export const clickaway = {
	data() {
		return {
			show: false,
			clickaway_close: true
		}
	},

	mounted() {
		document.addEventListener("click", this.closePopup);
	},

	unmounted() {
		document.removeEventListener("click", this.closePopup);
	},

	methods: {
		clickawayClose(event) {
			this.clickaway_close = false;

			const path = event.path || (event.composedPath ? event.composedPath() : undefined);

			if (path) {
				for (const item of path) {
					if (item?.classList?.contains('dropdown-item')) this.closePopup();
				}
			}
		},

		closePopup() {
			if (this.clickaway_close) this.show = false;

			this.clickaway_close = true;
		},

		openPopup() {
			if (this.show) {
				this.clickaway_close = true;

				this.closePopup();
			} else {
				setTimeout(() => { this.show = true }, 0)
			}
		}
	}
}
