import Swal from 'sweetalert2';
import { defineAsyncComponent } from 'vue';
import { checkDelOrders } from "./forms/api";

export default {
	forms: {
		edit: {
			component: defineAsyncComponent(() => import('./forms/Edit.vue'))
		},
		list: {
			component: defineAsyncComponent(() => import('./forms/List.vue'))
		},
		select: {
			panel: {
				width: '60%'
			},
			config: {
				fields: {
					id: {
						config: {
							visible: false
						}
					},
					name: {},
					time_start: {},
					time_end: {},
					active: {}
				}
			}
		}
	},

	onBeforeDelete: async function (data: any) {
		const brigadeData: any = await checkDelOrders(data.id);

		if (brigadeData) {
			Swal.fire('Внимание', 'Удаление смены невозможно, у бригад есть переданные вызова!', 'warning');

			return false;
		}

		return true;
	}
}