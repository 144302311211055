<template>
	<div class="context-menu dropdown-menu show" :style="{ top: contextMenu.top, left: contextMenu.left }" v-if="contextMenu.show">
		<template v-for="(item, key) of contextMenu.items" :key="key">
			<div class="dropdown-divider" v-if="item.caption == '-'"></div>
			<a class="dropdown-item" href="#" @click.prevent="item.onClick" v-else>
				<component :is="item.icon" v-if="item.icon"></component>
				<i class="icon icon-minus" v-else></i>
				{{ item.caption }}
			</a>
		</template>
	</div>
</template>

<script lang="ts">
import { onMounted, onUnmounted } from 'vue';

import { contextMenu } from "@/core/openContextMenu";

export default {
	setup() {
		const closeMenu = () => contextMenu.show = false;

		const handleEscapeKey = (e: any) => contextMenu.show && e.keyCode == 27 && closeMenu();

		onMounted(() => {
			document.body.addEventListener('keyup', handleEscapeKey)
			document.addEventListener("click", closeMenu);
		})

		onUnmounted(() => {
			document.body.removeEventListener('keyup', handleEscapeKey)
			document.removeEventListener("click", closeMenu);
		})

		return {
			contextMenu
		}
	}
}
</script>

<style>
.context-menu {
	position: absolute;
	transform: translate3d(5px, 5px, 0px);
	will-change: transform;
}
</style>
