import { defineAsyncComponent, h } from 'vue';

import DBStoreRecord from '@/core/db_store_record';
import { deepClone, deepMerge } from '@/core/helpers/utils';
import { caption, openPanel } from '@/core/layouts';

export const edit = async (params: any = {}): Promise<any> => {
	return new Promise((resolve) => {
		const { group = false } = params?.config ? params.config : {};

		const settings = Object.assign({
			table: '',
			copy: false,
			store: null,
			data: {},
			config: {},
			form: group ? 'editgroup' : 'edit',
			owner: null,
			readonly: false,
			caption: null,
			payload: null,
			defaults: null
		}, params);

		if (settings.store || settings.table) {
			const table = settings.store ? settings.store.name : settings.table;

			const schema = getModel(table);

			const forms = schema?.forms;

			//Секция form/edit
			const editForm = deepMerge({}, forms ? forms[settings.form] : {}, settings.config);

			const config = deepMerge({}, editForm?.config ? editForm.config : {});

			const store = new DBStoreRecord(table, config);

			if (settings?.store?.model?.depends && settings?.store?.model?.ownerField && settings?.store?.owner) {
				store.owner = settings.store.owner;
			}

			if (settings.store) {
				store.model = settings.store.model;
				store.files = settings.store.files;
			}

			if (settings.owner) {
				store.owner = settings.owner;
				config['owner'] = settings.owner;
			}

			const access = store.model.access;
			store.state.readonly = settings.readonly || !(access.create || access.update || access.delete);

			if (schema) settings.caption = settings.caption ? settings.caption : schema.description;

			const component = editForm?.component ? editForm.component : defineAsyncComponent(() => import('@/core/components/DB/Edit.vue'));

			const paramsPanel = deepMerge(
				{
					modal: caption.value == '',
					scrollable: !editForm?.component,
					caption: schema ? `${settings.copy ? 'Копирование' : params.data[schema.key] ? 'Редактирование' : 'Создание'} (${settings.caption})` : 'Редактирование',
					onCreate: (panel: any) => {
						return ({
							component: h(component, {
								panel,
								store,
								config,
								data: settings.data,
								copy: settings.copy,
								defaults: settings.defaults,
								payload: settings.payload
							})
						})
					},
					onClose: (result: any) => resolve(result)
				},
				editForm?.panel ? editForm.panel : {}
			)

			const { id = null } = settings.data;
			if (id) paramsPanel.key = id;

			openPanel(paramsPanel);
		} else resolve(null);
	})
}

export const editRecId = async (table: string, id: string, owner: any = null, config: any = {}): Promise<any> => {
	return id && edit({
		table,
		data: { id },
		owner,
		config
	});
}

export const newRec = async (table: string, data: any, owner: any = null, config: any = {}): Promise<any> => {
	return edit({
		table,
		data: Object.assign(data, { id: null }),
		owner,
		config
	});
}

export const select = (table: string, id = '', owner: any = null, options: any = {}): Promise<any> => {
	return new Promise((resolve) => {
		const schema = getModel(table);

		if (!schema) resolve(null);

		const { group = false } = options;

		//Указываем в props.group = true или config.options.group = true) что поле это выбор раздела справочника
		const select = group && schema?.forms?.selectgroup ? schema.forms.selectgroup : schema?.forms?.select;

		const config: any = Object.assign({ slectMode: true, id }, select?.config ? select.config : {}, options ? options : {});

		if (owner) config['owner'] = owner;

		const component = select?.component ? select.component : defineAsyncComponent(() => import('@/core/components/DB/Select.vue'));

		const panelConfig = Object.assign(select?.panel ? select.panel : {}, options?.panel ? options.panel : {});

		return openPanel(Object.assign({
			modal: true,
			caption: `Выбор (${schema.description})`,
			onCreate: (panel: any) => ({
				component: h(
					component,
					{
						table,
						panel,
						config
					}
				)
			}),
			onClose: (result: any) => resolve(result)
		}, panelConfig));
	})
}

const tables: any = {}

export const schema: any = {};

const reduceFields = (fields: any) => {
	for (const keyField in fields) {
		const field = fields[keyField];

		if (typeof field.type == 'object') {
			if (field.type.table && typeof field.type.table == 'string') {
				field.table = field.type.table;
				field.type.table = getModel(field.type.table);
				// field.type.table.subtable = true;
				field.ownerField = field.type.table.ownerField;

				reduceFields(field.type.table.fields);
			}
		}
	}
}

//Грузим один раз при запуске программы
export const loadTables = (dataTables: any) => Object.assign(tables, dataTables);

//Грузим при входе, выходе, обновлении
export const loadSchema = (dbSchema: any) => {
	for (const key in schema) delete schema[key];

	for (const key in dbSchema) {
		schema[key] = deepMerge(dbSchema[key], tables[key] ? tables[key] : {});
	}

	for (const key in dbSchema) {
		reduceFields(dbSchema[key].fields);
	}
}

export const getModel = (name: string) => {
	const model = schema[name];

	if (model) {
		const _model = deepClone(model);

		//Если напрямую запрашивается модель табличной части, то удаляем признак "subtable"
		if (name.split('.').length > 1 && _model.subtable) delete _model.subtable;

		return _model;
	} else {
		return tables[name] ? deepMerge({ offLine: true }, tables[name]) : { offLine: true };
	}
}
