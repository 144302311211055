import { vModelText as _vModelText, withModifiers as _withModifiers, withKeys as _withKeys, resolveDirective as _resolveDirective, createElementVNode as _createElementVNode, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "input-group mb-1" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = { class: "input-group-append" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_focus = _resolveDirective("focus")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", {
      placeholder: _ctx.placeholder,
      class: "form-control",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.text) = $event)),
      onKeydown: _cache[1] || (_cache[1] = _withKeys(_withModifiers(
//@ts-ignore
(...args) => (_ctx.onSearch && _ctx.onSearch(...args)), ["prevent"]), ["enter"])),
      ref: "input"
    }, null, 40, _hoisted_2), [
      [_directive_focus],
      [_vModelText, _ctx.text]
    ]),
    _createElementVNode("div", _hoisted_3, [
      (!_ctx.searchAway)
        ? (_openBlock(), _createElementBlock("button", {
            key: 0,
            type: "button",
            class: "btn btn-trig",
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onSearch && _ctx.onSearch(...args)))
          }, _cache[4] || (_cache[4] = [
            _createElementVNode("i", { class: "icon icon-search1" }, null, -1)
          ])))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        type: "button",
        class: "btn btn-trig",
        onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.onClear && _ctx.onClear(...args)))
      }, _cache[5] || (_cache[5] = [
        _createElementVNode("i", { class: "icon icon-x" }, null, -1)
      ]))
    ])
  ]))
}