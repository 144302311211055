import { ref } from "vue";
import DBStoreRecord from "@/core/db_store_record";
import { genGUID, srcToBlob } from "@/core/helpers/utils";
import { config } from "@/config";

export default class DBEditorController {
	public store: DBStoreRecord;
	public data: any;
	public form: any;
	public field: any;

	public structure: any;
	public labelId: string;
	public editor: any = null;

	constructor(public props: any) {
		this.labelId = `editor_${genGUID()}`;

		this.form = props.form;
		this.store = props.form.store;
		this.data = this.store.data;
		this.field = ref(this.props.field);

		this.field.value = this.props.field;

		const field = this.field;

		this.structure = this.getFieldModel(field.value.split('.'), this.store.state);
	}

	getFieldModel(fields: any, structure: any): any {
		const field: any = fields.shift();

		if (fields.length > 0) {
			return this.getFieldModel(fields, structure.fields[field].model);
		} else {
			return structure.fields[field];
		}
	}

	get value() {
		const value = this.store.getValue(this.field.value);

		return value ? value : '';
	}

	set value(value: any) {
		this.store.setValue(this.field.value, value);
	}

	async afterSave() {
		this.editor.setContent(this.value, { format: 'html' });
	}

	async beforeSave() {
		let content = this.editor.getContent({ format: 'html' });

		let pos1 = content.indexOf('src="blob:');

		while (pos1 != -1) {
			const pos2 = content.indexOf('"', pos1 + 5);

			if (pos2 != -1) {
				const substr = content.substring(pos1 + 5, pos2);

				const blob = await srcToBlob(substr);

				const id = genGUID();

				content = content.replace(content.substring(pos1, pos2 + 1), `src="${config.api}/images/${id}"`);

				this.form.addBlob(blob, 'image.jpg', 'files', id);
			}

			pos1 = content.indexOf('src="blob:');
		}

		this.value = content;
	}
}
