import api from "@/core/api/api";

export interface ResultResponse {
	complete: boolean;
	message: string;
	data: any;
}

export const schema = () => api.query(`/db/schema`);

export const query = (query: any): any => api.query(`/db/query`, { data: query, method: "put" });

export const saveForm = (form: any, options: any = {}): any => api.query(
	'/db/form',
	Object.assign(
		{
			method: "put",
			data: form,
			headers: { 'Content-Type': 'multipart/form-data' }
		},
		options
	)
);

export const tableExport = (table: string, params: any = {}) => api.query(`/db/export/${table}`, { data: params, method: "post", responseType: 'blob' });