import { reactive, watch } from 'vue';

import Bus from '@/core/bus';
import Store from "@/core/store";
import { deepMerge } from '@/core/helpers/utils';
import { ResultResponse, query } from '@/core/components/DB/api';

import stateStore from "@/core/store/index";

import { getModel } from '@/core/db';

interface FetchOptions {
	id: string | null;
	owner: string | null;
	page: number;
	limit: number;
	filters: any;
	orderby: any;
	searchtext: string;
	params: any;
}

class DBStore extends Store {
	public data: any = reactive({
		rows: [],
		position: -1,
		select: [],
		rowsSelected: false
	});

	public pages = 0;

	public fetchOptions: FetchOptions = {
		id: null,
		owner: null,//id - зависимого поля
		page: 0,
		limit: 0,
		filters: [],
		orderby: [],
		searchtext: '',
		params: {}
	};

	constructor(public name: string, config: any = {}) {
		super();

		this.model = getModel(name);

		this.createState(config);

		Bus.$on('save-data', (saveData: any) => {
			if (saveData.table == this.name) {
				if (!this.model.ownerField || saveData.data[this.model.ownerField] == this.owner) {
					if (saveData.newrecord) {
						if (this.model.subtable) {
							this.push(saveData.data);
						} else {
							this.unshift(saveData.data);
						}
					} else {
						this.refreshData(saveData.data);
					}
				}
			}
		});

		watch(
			() => this.data.select,
			async () => {
				let i = 0;

				this.forEach((row: any) => {
					if (this.data.select.includes(row.id)) i++;
				})

				if (i > 0) {
					if (this.data.rows.length == i) {
						this.data.rowsSelected = true;
					} else {
						this.data.rowsSelected = null;
					}
				} else {
					this.data.rowsSelected = false;
				}
			},
			{
				deep: true
			}
		)
	}

	refreshData(data: any, setPosition = false) {
		const key = this.model.key;

		for (const row in this.data.rows) {
			if (this.data.rows[row][key] == data[key]) {
				if (setPosition) this.setPosition(Number(row));

				//Если не табличная часть, не офлайн таблица и есть sql поля, то запрашиваем представления этих полей
				if (!(this.model.subtable || this.model.offLine)) {
					for (const keyField in this.state.fields) {
						const field = this.state.fields[keyField];
						if (typeof field.type == 'object' && field.type.sql) {
							this.notion(this.name, data).then((notionData: any) => Object.assign(this.data.rows[row], notionData));

							break;
						}
					}
				}

				return Object.assign(this.data.rows[row], data);
			}
		}

		return null;
	}

	selectRow(data: any, select = true) {
		const id = data[this.model.key];

		if (select) {
			if (!this.data.select.includes(id)) this.data.select.push(id);
		} else {
			const index = this.data.select.indexOf(id);
			if (index != -1) this.data.select.splice(index, 1);
		}
	}

	getSelectedRows() {
		const result: any = [];

		this.data.select.forEach((el: any) => {
			for (const row of this.data.rows) {
				if (row[this.model.key] == el) result.push(row);
			}
		});

		return result;
	}

	setPosition(position: number) {
		this.data.position = this.data.rows.length > 0 && position < this.data.rows.length ? position : -1;
	}

	setPositionData(data: any) {
		for (const position in this.data.rows) {
			if (this.data.rows[position][this.model.key] == data[this.model.key]) {
				this.data.position = position;

				break;
			}
		}
	}

	push(data: any) {
		const result = this.refreshData(data, true);
		if (result) return result;

		const _data = this.createData(data);

		this.data.rows.push(_data);

		if (this.model.positionField) {
			let pos = 1;

			this.data.rows.forEach((el: any) => el[this.model.positionField] = pos++);
		}

		this.setPosition(this.data.rows.length - 1);

		return _data;
	}

	unshift(data: any) {
		const result = this.refreshData(data, true);
		if (result) return result;

		const _data = this.createData(data);

		this.data.rows.unshift(_data);

		if (this.model.positionField) {
			let pos = 1;

			this.data.rows.forEach((el: any) => el[this.model.positionField] = pos++);
		}

		this.setPosition(0);

		return _data;
	}

	deleteRec(position: number) {
		if (this.files[this.data.rows[position].id]) delete this.files[this.data.rows[position].id];

		this.selectRow(this.data.rows[position].id, false);

		this.data.rows.splice(position, 1);

		if (this.model.positionField) {
			let pos = 1;

			this.data.rows.forEach((el: any) => el[this.model.positionField] = pos++);
		}

		this.setPosition(position > this.data.rows.length - 1 ? this.data.rows.length - 1 : position);
	}

	async deleteRecord(data: any) {
		const key = data[this.model.key];

		if (key) {
			for (const row in this.data.rows) {
				const position: number = +row;

				if (key == this.data.rows[position][this.model.key]) {
					if (this.model.subtable || this.model.offLine) {
						this.deleteRec(position);
					} else {
						const response: ResultResponse = await query({
							table: this.name,
							method: 'delete',
							data: {
								[this.model.key]: key
							}
						});

						if (response.complete) this.deleteRec(position);
					}
				}
			}
		}
	}

	loadData(data: any = [], setPos = true) {
		this.clear();

		for (const row of data) {
			this.data.rows.push(this.createData(row));
		}

		if (this.data.rows.length > 0 && setPos) this.setPosition(0);

		this.state.loaded = true;
	}

	fetchParams(options = {}) {
		const params = deepMerge({}, this.fetchOptions, options);

		if (typeof params.filters == 'function')
			params.filters = params.filters(params.data ? params.data : this.data);

		if (typeof params.params == 'function')
			params.params = params.params(params.data ? params.data : this.data);

		if (!params.fields && this.state.fields)
			params.fields = this.getFetchFields();

		if (!params.id) delete params.id;
		if (!params.owner) delete params.owner;
		if (!params.limit) delete params.limit;
		if (!params.limit && !params.page) delete params.page;
		if (!params.searchtext) delete params.searchtext;

		if (params.filters.length == 0) delete params.filters;
		if (params.orderby.length == 0) delete params.orderby;
		if (Object.keys(params.params).length == 0) delete params.params;

		if (this.owner) params.owner = this.owner;

		return params;
	}

	async fetchData(options: any = {}, setPos = true) {
		if (!(this.model.subtable || this.model.offLine)) {
			if (this.model.ownerField && !this.owner) return;

			stateStore.state.load = true;

			const data = deepMerge({
				table: this.name,
				method: 'read',
				params: this.fetchParams(options)
			});

			const response: ResultResponse = await query(data);

			if (response.complete) {
				const rows = response.data?.rows;

				if (rows) {
					this.loadData(rows, setPos);

					if (this.fetchOptions.limit) {
						this.pages = Math.ceil(response.data.rowCount / this.fetchOptions.limit);
					}
				}
			}

			stateStore.state.load = false;
		}

		return this.data.rows;
	}

	dataForSave(data: any): any {
		return data.map((row: any) => this.getData(row));
	}

	currentData() {
		return this.data.position != -1 && this.data.rows.length > 0 ? this.data.rows[this.data.position] : null;
	}

	clear() {
		this.data.rows.splice(0, this.data.rows.length);
		this.data.position = -1;

		this.clearSelected();
	}

	clearSelected() {
		this.data.select.splice(0, this.data.select.length);
	}

	setRows(rows: any) {
		this.clear();

		rows.forEach((row: any) => this.data.rows.push(row))
		this.data.position = this.data.rows.length > 0 ? 0 : -1;
	}

	forEach(callback: any) {
		this.data.rows.forEach((row: any) => callback(row));
	}
}

export default DBStore;
