import Swal from 'sweetalert2';
import { defineAsyncComponent } from 'vue';

export default {
	forms: {
		edit: {
			component: defineAsyncComponent(() => import('./forms/Edit.vue'))
		},
		list: {
			component: defineAsyncComponent(() => import('./forms/List.vue'))
		},
		select: {
			panel: {
				width: '70%',
				height: '500px'
			},
			config: {
				sorting: true,
				orderBy: { name: true },
				fields: {
					name: {
						config: {
							sorting: '"brigades"."name"'
						}
					},
					brigade: {
					},
					type: {
					},
					order: {
						config: {
							sorting: '"order"."time_start"'
						}
					}
				}
			}
		}
	},

	onBeforeDelete: async function (data: any) {
		if (data.amount > 0) {
			Swal.fire('Внимание', 'Удаление бригады невозможно, есть переданные вызова!', 'warning');

			return false;
		}

		return true;
	}
}