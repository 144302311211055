import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, withModifiers as _withModifiers, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = ["onClick", "onDblclick", "onContextmenu"]
const _hoisted_2 = {
  key: 0,
  style: {"width":"20px","text-align":"center"}
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = {
  key: 1,
  style: {"width":"25px","text-align":"center"}
}
const _hoisted_5 = ["checked", "onClick"]
const _hoisted_6 = {
  key: 2,
  style: {"width":"40px","text-align":"center"}
}
const _hoisted_7 = { key: 0 }
const _hoisted_8 = ["innerHTML"]
const _hoisted_9 = ["innerHTML", "onClick"]
const _hoisted_10 = ["innerHTML"]
const _hoisted_11 = { key: 1 }
const _hoisted_12 = ["colspan"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("tbody", null, [
    (_ctx.controller.data.length > 0)
      ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(_ctx.controller.data, (row, key) => {
          return (_openBlock(), _createElementBlock("tr", {
            key: row.id,
            class: _normalizeClass(_ctx.getClassTr(key, row)),
            style: _normalizeStyle(_ctx.getStyleTr(row)),
            onClick: () => _ctx.onActive(key),
            onDblclick: () => _ctx.$emit('select', row),
            onContextmenu: _withModifiers((event) => _ctx.onOpenMenu(event, row, key), ["prevent"]),
            ref_for: true,
            ref: el => { if (el) _ctx.rows[key] = el },
            tabindex: "-1"
          }, [
            (_ctx.config.menu)
              ? (_openBlock(), _createElementBlock("th", _hoisted_2, [
                  _createElementVNode("a", {
                    href: "#",
                    onClick: _withModifiers((event) => _ctx.onOpenMenu(event, row, key), ["prevent"])
                  }, _cache[0] || (_cache[0] = [
                    _createElementVNode("i", { class: "icon icon-menu" }, null, -1)
                  ]), 8, _hoisted_3)
                ]))
              : _createCommentVNode("", true),
            (_ctx.config.rowSelect)
              ? (_openBlock(), _createElementBlock("th", _hoisted_4, [
                  _createElementVNode("input", {
                    type: "checkbox",
                    class: "row-checkbox",
                    checked: _ctx.store.data.select.includes(row.id),
                    onClick: (e) => _ctx.selectRow(e, row)
                  }, null, 8, _hoisted_5)
                ]))
              : _createCommentVNode("", true),
            (_ctx.config.numbering)
              ? (_openBlock(), _createElementBlock("th", _hoisted_6, _toDisplayString(_ctx.getNumbering(key)), 1))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.fields, (field, fieldkey) => {
              return (_openBlock(), _createElementBlock(_Fragment, { key: fieldkey }, [
                (_ctx.visible(field))
                  ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                      (field.config.width)
                        ? (_openBlock(), _createElementBlock("td", _hoisted_7, [
                            _createElementVNode("div", {
                              class: _normalizeClass({ cutoff: !!field.config.cutoff }),
                              style: _normalizeStyle({ width: field.config.width }),
                              innerHTML: _ctx.getData(field, fieldkey, row)
                            }, null, 14, _hoisted_8)
                          ]))
                        : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                            (!!_ctx.config.cellClick[fieldkey])
                              ? (_openBlock(), _createElementBlock("td", {
                                  key: 0,
                                  style: _normalizeStyle(_ctx.getStyleTd(field, row))
                                }, [
                                  _createElementVNode("a", {
                                    innerHTML: _ctx.getData(field, fieldkey, row),
                                    href: "#",
                                    onClick: _withModifiers(() => _ctx.config.cellClick[fieldkey](row, _ctx.controller), ["prevent"])
                                  }, null, 8, _hoisted_9)
                                ], 4))
                              : (_openBlock(), _createElementBlock("td", {
                                  key: 1,
                                  innerHTML: _ctx.getData(field, fieldkey, row),
                                  style: _normalizeStyle(_ctx.getStyleTd(field, row))
                                }, null, 12, _hoisted_10))
                          ], 64))
                    ], 64))
                  : _createCommentVNode("", true)
              ], 64))
            }), 128))
          ], 46, _hoisted_1))
        }), 128))
      : (_openBlock(), _createElementBlock("tr", _hoisted_11, [
          _createElementVNode("td", {
            colspan: Object.keys(_ctx.store.state.fields).length + (_ctx.config.menu ? 1 : 0) + (_ctx.config.numbering ? 1 : 0)
          }, " ", 8, _hoisted_12)
        ]))
  ]))
}