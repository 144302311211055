import stateStore from "@/core/store/index";

import { isArray } from "./utils";

export function availabeRole(value: any) {
	const roles: string[] = stateStore.state.user.roles;

	if (typeof value == 'boolean') {

		return value;

	} else if (typeof value == 'string') {

		return roles.includes(value);

	} else if (isArray(value)) {

		for (const role of value) {
			if (roles.includes(role)) return true;
		}
	}

	return false;
}
