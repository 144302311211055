import { defineAsyncComponent } from 'vue';

export default ({
	forms: {
		edit: {
			panel: {
				width: '80%'
			},
			component: defineAsyncComponent(() => import('./Edit/index.vue'))
		},
		editgroup: {
			panel: {
				width: '80%'
			},
			component: defineAsyncComponent(() => import('./EditGroup/index.vue'))
		},
		list: {
			config: {},
			component: defineAsyncComponent(() => import('./List/index.vue'))
		},
		select: {
			panel: {
				width: '60%'
			},
			component: defineAsyncComponent(() => import('./Select/index.vue'))
		}
	}
})