import { createRouter, createWebHistory } from "vue-router";

import stateStore from "@/core/store/index";
import { breadCrumb, closeAllPanels, pageTitle, panels, setCaption } from "@/core/layouts";

import routes from './routes';

const router = createRouter({ history: createWebHistory(process.env.BASE_URL), routes });

export const openRoute = (path: string) => {
	const newRoute = path.split('/').filter(el => el);
	const currentRoute = router.currentRoute.value.path.split('/').filter(el => el);

	if (currentRoute.length > 0 && newRoute.length > 0 && currentRoute[0] == newRoute[0]) router.replace(path);
}

router.beforeEach(async (to, from) => {
	//Прописывать в роутах роли и проверять доступ при переходах
	if (to.fullPath != from.fullPath) {
		for (const panel of panels.value) {
			if (panel.onBeforeClose) {
				if (!await panel.onBeforeClose()) return false;
			}
		}

		closeAllPanels();

		setCaption('');
	}

	//Получаем статус пользователя
	const user: any = stateStore.state.user.id ? stateStore.state.user : await stateStore.dispatch('status');

	//Если в метаданных прописаны роли, то производим проверку на доступ, если не указана ни одна роль - доступ запрещен
	if (to.meta.roles) {
		const roles: any = to.meta.roles;

		for (const routeRole of roles) {
			if (user.roles.includes(routeRole)) return true;
		}

		return { path: '/404' };
	} else {
		return true;
	}
})

router.afterEach((to) => {
	breadCrumb.value = to.meta.breadCrumb ? to.meta.breadCrumb : [];

	pageTitle.value = to.meta.title ? to.meta.title : null;
})

export default router;
