<template>
	<tbody>
		<template v-if="controller.data.length > 0">
			<template v-for="(row, key) of controller.data" :key="row.id">
				<tr
					:class="getClassTr(key, row)"
					:style="getStyleTr(row)"
					@click="() => onActive(key)"
					@dblclick="() => $emit('select', row)"
					@contextmenu.prevent="(event) => onOpenMenu(event, row, key)"
					:ref="el => { if (el) rows[key] = el }"
					tabindex="-1">

					<th v-if="config.menu" style="width: 20px; text-align: center;">
						<a href="#" @click.prevent="(event) => onOpenMenu(event, row, key)">
							<i class="icon icon-menu"></i>
						</a>
					</th>

					<th v-if="config.rowSelect" style="width: 25px;text-align: center;">
						<input type="checkbox" class="row-checkbox" :checked="store.data.select.includes(row.id)" @click="(e) => selectRow(e, row)" />
					</th>

					<th v-if="config.numbering" style="width: 40px; text-align: center;">{{ getNumbering(key) }}</th>

					<template v-for="(field, fieldkey) of store.state.fields" :key="fieldkey">
						<template v-if="visible(field)">
							<template v-if="field.config.width">
								<td>
									<div :class="{ cutoff: !!field.config.cutoff }" :style="{ width: field.config.width }" v-html="getData(field, fieldkey, row)"></div>
								</td>
							</template>
							<template v-else>
								<template v-if="!!config.cellClick[fieldkey]">
									<td :style="getStyleTd(field, row)">
										<a v-html="getData(field, fieldkey, row)" href="#" @click.prevent="() => config.cellClick[fieldkey](row, controller)"></a>
									</td>
								</template>
								<td v-html="getData(field, fieldkey, row)" :style="getStyleTd(field, row)" v-else></td>
							</template>
						</template>
					</template>
				</tr>
			</template>
		</template>
		<template v-else>
			<tr>
				<td :colspan="Object.keys(store.state.fields).length + (config.menu ? 1 : 0) + (config.numbering ? 1 : 0)">&nbsp;</td>
			</tr>
		</template>
	</tbody>
</template>

<script lang="tsx">
import dayjs from "dayjs";
import { defineComponent } from 'vue';
import openContextMenu from '@/core/openContextMenu';

export default defineComponent({
	emits: ['active', 'select'],

	props: {
		controller: {
			type: Object,
			default: () => ({})
		},
		crossot: {
			type: Function,
			default: () => false
		}
	},

	setup(props: any, { emit }: { emit: any }) {
		const store = props.controller.store;

		const config = props.controller.settings;

		const rows: any = props.controller.rows;

		const getData = (structure: any, field: any, data: any) => {
			const calc = structure?.config?.calc;

			if (calc) return typeof calc == 'function' ? calc(data) : calc;

			let value = data[field];

			if (data[`_${field}`]) {
				value = data[`_${field}`];
			} else {
				if (typeof structure.type == 'object') {
					if (structure.type.table) {
						value = value ? `Objects [${value.length}]` : null;
					} else if (structure.type.fields) {
						const field_structure = store.model.fields[data[field]];

						value = field_structure ? getData(field_structure, data[field], data) : null;
					}
				} else {
					switch (structure.type) {
						case 'DECIMAL':
							if (structure?.config?.precision) {
								value = Number(value && !isNaN(value) ? value : 0).toFixed(structure.config.precision);
							} else {
								value = Number(value && !isNaN(value) ? value : 0);
							}

							break;
						case 'DATEONLY':
							if (data[field] && data[field] != '0000-00-00') value = dayjs(data[field]).format('DD.MM.YYYY');

							break;
						case 'TIME':
							if (data[field]) value = data[field];

							break;
						case 'DATE':
							if (data[field]) value = dayjs(data[field]).format('DD.MM.YYYY HH:mm:ss');

							break;
						case 'BOOLEAN':
							value = data[field] ? '<i class="icon icon-check"></i>' : '';

							break;
					}
				}
			}

			return value;
		}

		const getStyleTd = (structure: any, data: any) => {
			const result = {};

			if (structure?.config.style) {
				if (typeof structure.config.style == 'function') {
					Object.assign(result, structure.config.style(data))
				} else if (typeof structure.config.style == 'object') {
					Object.assign(result, structure.config.style)
				}
			}

			return result;
		}

		const getStyleTr = (data: any) => {
			const result = {};

			if (config.styleTr) {
				if (typeof config.styleTr == 'function') {
					Object.assign(result, config.styleTr(data))
				} else if (typeof config.styleTr == 'object') {
					Object.assign(result, config.styleTr)
				}
			}

			return result;
		}

		const getNumbering = (numberRow: number) => store.fetchOptions.page * store.fetchOptions.limit + numberRow + 1;

		const onActive = (position: number) => emit('active', position);

		const getClassTr = (key: number, data: any) => {
			const classes = {
				selectrow: key == store.data.position,
				'table-info': key == store.data.position,
				'line-through': props.crossot(data)
			}

			if (config.classTr) {
				if (typeof config.classTr == 'function') {
					Object.assign(classes, config.classTr(data))
				} else if (typeof config.classTr == 'object') {
					Object.assign(classes, config.classTr)
				}
			}

			return classes;
		}


		const onOpenMenu = (event: any, data: any, position: number) => {
			emit('active', position);

			config.menu && openContextMenu(event, props.controller.contextMenuBody(data));
		}

		const selectRow = (e: any, row: any) => {
			config.onRowSelect(row, e.target.checked);

			e.target.checked = false;
		}

		const visible = (field: any) => {
			const config = field?.config;

			return (typeof config.visible == 'function' ? config.visible() : config.visible) && !config.hide;
		}

		return {
			config,
			store,
			rows,
			onActive,
			onOpenMenu,
			getData,
			getNumbering,
			getClassTr,
			getStyleTd,
			getStyleTr,
			selectRow,
			visible
		}
	}
})
</script>

<style scoped>
.row-checkbox {
	position: relative;
	left: 0px;
	opacity: 1;
	top: 3px;
}

.line-through {
	text-decoration: line-through;
}
</style>
