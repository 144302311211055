<script>
import { defineComponent, h } from 'vue';

export default defineComponent({
	props: {
		items: {
			type: Array,
			default: () => []
		},
		classPanelFun: {
			type: String,
			default: 'btn-group btn-group-sm mr-1'
		}
	},

	setup(props) {
		const createDropdownMenu = (items) => {
			const result = [];

			for (const key in items) {
				const item = items[key];

				if (item) {
					const elements = [];

					if (item.icon) elements.push(item.icon);
					if (item.caption) elements.push(` ${item.caption}`);

					if (item.items) {
						result.push(
							h(
								'div',
								{
									class: 'dropdown-submenu'
								},
								[
									h('a', { href: "#", class: "dropdown-item" }, elements),
									h('div', { class: "dropdown-menu" }, createDropdownMenu(item.items))
								]
							)
						);
					} else {
						const params = {
							href: '#',
							class: 'dropdown-item',
							title: item.title ? item.title : null,
							onClick: item.onClick ? item.onClick : null
						}

						result.push(h('a', params, elements));
					}
				}
			}

			return result;
		}

		return () => {
			const
				style = 'position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, 36px, 0px);',
				panels = [];

			for (const item of props.items) {
				const buttons = [];
				const dropdown = [];

				for (const key in item) {
					const button = item[key];

					if (button && Object.keys(button).length > 0) {
						const elements = [];

						if (button.icon) elements.push(button.icon);
						if (button.caption) elements.push(` ${button.caption}`);

						const classes = [button.class ? button.class : 'btn btn-trig'];

						if (button.items) {
							classes.push('dropdown-toggle');

							dropdown.push(
								h(
									'div',
									{
										class: 'dropdown-menu',
										style
									},
									createDropdownMenu(button.items, style)
								)
							)
						}

						buttons.push(
							h(
								'button',
								{
									type: 'button',
									class: classes.join(' '),
									title: button.title ? button.title : null,
									'data-toggle': button.items ? 'dropdown' : null,
									onClick: button.onClick ? button.onClick : null
								},
								elements
							)
						);
					}
				}

				panels.push(h('div', { class: props.classPanelFun }, [...buttons, ...dropdown]));
			}

			return h('div', { class: 'btn-toolbar' }, panels)
		}
	}
})
</script>

<style></style>
