import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "light-thead" }
const _hoisted_2 = {
  key: 0,
  style: {"width":"20px","text-align":"center"}
}
const _hoisted_3 = {
  key: 1,
  style: {"width":"25px","text-align":"center"}
}
const _hoisted_4 = ["indeterminate", "checked"]
const _hoisted_5 = {
  key: 2,
  style: {"width":"40px","text-align":"center"}
}
const _hoisted_6 = ["onClick", "innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("thead", _hoisted_1, [
    _createElementVNode("tr", {
      onContextmenu: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onOpenMenu && _ctx.onOpenMenu(...args)))
    }, [
      (_ctx.config.menu)
        ? (_openBlock(), _createElementBlock("th", _hoisted_2, [
            _createElementVNode("a", {
              href: "#",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onOpenMenu && _ctx.onOpenMenu(...args)))
            }, _cache[3] || (_cache[3] = [
              _createElementVNode("i", { class: "icon icon-menu" }, null, -1)
            ]))
          ]))
        : _createCommentVNode("", true),
      (_ctx.config.rowSelect)
        ? (_openBlock(), _createElementBlock("th", _hoisted_3, [
            _createElementVNode("input", {
              type: "checkbox",
              class: "row-checkbox",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.selectRows && _ctx.selectRows(...args))),
              indeterminate: _ctx.store.data.rowsSelected === null,
              checked: _ctx.store.data.rowsSelected
            }, null, 8, _hoisted_4)
          ]))
        : _createCommentVNode("", true),
      (_ctx.config.numbering)
        ? (_openBlock(), _createElementBlock("th", _hoisted_5, "№"))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.store.state.fields, (field, fieldkey) => {
        return (_openBlock(), _createElementBlock(_Fragment, { key: fieldkey }, [
          (_ctx.visible(field))
            ? (_openBlock(), _createElementBlock("th", {
                key: 0,
                style: _normalizeStyle(_ctx.getStyleTh(field)),
                class: _normalizeClass(_ctx.getClassTh(fieldkey)),
                onClick: (event) => _ctx.onClickCell(event, fieldkey, field),
                innerHTML: field.description
              }, null, 14, _hoisted_6))
            : _createCommentVNode("", true)
        ], 64))
      }), 128))
    ], 32)
  ]))
}