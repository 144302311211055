import Swal from "sweetalert2";
import ReconnectingWebSocket from 'reconnecting-websocket';

import Bus from '@/core/bus'
import stateStore from '@/core/store/index'

import { config } from "@/config";

//Использовать Bus
class WSClient {
	private ws: any;
	constructor() {
		this.ws = new ReconnectingWebSocket(config.ws);

		this.ws.connectionTimeout = 10000;

		this.ws.onopen = () => {
			stateStore.dispatch('online', true);

			this.send({
				event: 'login',
				data: stateStore.state.token
			});
		};

		this.ws.onmessage = (message: any) => {
			const json = JSON.parse(message.data);

			// console.log(json);

			Bus.$emit('ws-data', json);

			if (json.message) {
				stateStore.dispatch('newMessage', json.message);
			} else if (json.logout) {
				stateStore.dispatch('logout');

				Swal.fire({
					title: 'Сессия завершена администратором',
					showCancelButton: false,
					confirmButtonText: 'Закрыть'
				});
			}
		}

		this.ws.onclose = () => {
			stateStore.dispatch('online', false);
		}
	}

	send(message = {}) {
		if (!stateStore.state.online) return false;

		this.ws.send(JSON.stringify(message));

		return true;
	}
}

export default new WSClient;
