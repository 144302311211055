import Swal from "sweetalert2";
import { computed, nextTick, ref } from "vue";

import { edit, getModel } from "@/core/db";

import DBStore from "@/core/db_store";
import openContextMenu from "@/core/openContextMenu";

import { deepMerge } from "@/core/helpers/utils";
import { toastError } from '@/core/helpers/toastify';

import { query } from "../api";

export default class DBTreeController {
	public store: DBStore;

	public tree: any;
	public data: any;
	public settings: any;
	public access: any;
	public readonly: any;
	public panelFun: any;
	public standardCommands: any;
	public table: any;

	private thisNode: any;

	constructor(protected props: any, protected emit: any) {
		this.tree = ref(null);
		this.data = ref([]);
		this.panelFun = ref([]);
		this.store = props.store;

		this.table = this.store.name;

		this.settings = Object.assign(
			{
				id: null,
				height: null,
				panelFun: true,
				notion: (data: any) => data.text,
				contextMenu: [],
				filters: [],
				fetchParams: {}
			},
			props.config
		)

		const model: any = getModel(this.table);

		this.access = model.access;

		this.readonly = computed(() => !(this.access.create || this.access.update || this.access.delete));

		this.standardCommands = {
			edit: {
				caption: 'Изменить',
				title: 'Изменить',
				icon: <i class="icon icon-edit"></i>,
				class: 'btn btn-edit',
				onClick: () => this.nodeEdit(this.thisNode)
			},
			view: {
				caption: 'Просмотр',
				title: 'Просмотр',
				icon: <i class="icon icon-eye"></i>,
				class: 'btn btn-edit',
				onClick: () => this.nodeEdit(this.thisNode)
			},
			add: {
				caption: 'Добавить',
				title: 'Добавить',
				icon: <i class="icon icon-plus-circle"></i>,
				class: 'btn btn-add',
				onClick: () => this.nodeAdd(this.thisNode)
			},
			addFolder: {
				caption: 'Добавить группу',
				title: 'Добавить группу',
				icon: <i class="icon icon-plus-circle"></i>,
				class: 'btn btn-add',
				onClick: () => this.nodeAdd(this.thisNode, true)
			},
			copy: {
				caption: 'Копировать',
				title: 'Копировать',
				icon: <i class="icon icon-file"></i>,
				class: 'btn btn-copy',
				onClick: () => this.nodeCopy(this.thisNode)
			},
			delete: {
				caption: 'Удалить',
				title: 'Удалить',
				icon: <i class="icon icon-x-circle"></i>,
				class: 'btn btn-delete',
				onClick: () => this.nodeDelete(this.thisNode)
			}
		}

		this.createPanelFun([
			{
				edit: this.access.update ? {} : null,
				add: this.access.create && this.props.elements ? {} : null,
				addFolder: this.access.create && this.props.folders ? {} : null,
				copy: this.access.create ? {} : null,
				delete: this.access.delete ? {} : null
			}
		])
	}

	createPanelFun(items: any) {
		this.panelFun.value.splice(0, this.panelFun.value.length);

		for (const item of items) {
			const panel: any = {};

			for (const key in item) {
				if (item[key]) {
					panel[key] = {};

					if (key in this.standardCommands) {
						Object.assign(panel[key], this.standardCommands[key], item[key]);
					} else {
						Object.assign(panel[key], item[key]);
					}
				}
			}

			this.panelFun.value.push(panel);
		}
	}

	getContextMenu(node: any) {
		const items = [];

		if (this.access.update)
			items.push({
				icon: <i class="icon icon-edit"></i>,
				caption: 'Изменить',
				onClick: () => this.nodeEdit(node)
			})

		if (this.access.create) {
			if (this.props.elements)
				items.push({
					icon: <i class="icon icon-plus-circle"></i>,
					caption: 'Добавить',
					onClick: () => this.nodeAdd(node)
				})

			if (this.props.folders)
				items.push({
					icon: <i class="icon icon-folder"></i>,
					caption: 'Добавить раздел',
					onClick: () => this.nodeAdd(node, true)
				})

			items.push({
				icon: <i class="icon icon-file"></i>,
				caption: 'Копировать',
				onClick: () => this.nodeCopy(node)
			})
		}

		if (this.access.delete)
			items.push({
				icon: <i class="icon icon-x-circle"></i>,
				caption: node.folder ? 'Удалить раздел' : 'Удалить',
				onClick: () => this.nodeDelete(node)
			})

		return items.concat(typeof this.settings.contextMenu == 'function' ? this.settings.contextMenu(node) : this.settings.contextMenu);
	}

	active(node: any) {
		const tree = this.tree.value;

		this.thisNode = node;

		tree.setActive(node);

		this.emit('active', node.data);
	}

	async nodeEdit(node: any) {
		const parent = node.data.parent;

		const data = await edit({
			table: this.table,
			data: { id: node.id },
			form: node.folder ? 'editgroup' : 'edit'
		});

		if (data) {
			if (parent != data.parent) {
				const tree = this.tree.value;

				const item = tree.findNode(data.parent);

				const delNode = tree.deleteNode(node.id);

				if (item.children) {
					item.children.push(delNode);
					delNode.parent = item;
					delNode.data.parent = item.data.id;
				}

				tree.controller.openParents(delNode);
			} else {
				node.folder = data.node;

				if (node.folder) {
					node.expanded = true;
					if (!node.children) node.children = [];
				} else {
					if (node.children) delete node.children;
				}
			}
		}
	}

	async nodeCopy(node: any) {
		const data = await edit({
			copy: true,
			table: this.table,
			data: { id: node.id },
			form: node.folder ? 'editgroup' : 'edit'
		});

		if (data) {
			const tree = this.tree.value;

			if (node.folder) node.expanded = true;

			const newNode = tree.createNode(data, node.parent.children, node.parent);
			this.active(newNode);
		}
	}

	async nodeDelete(node: any) {
		const deleteNode = async () => {
			await query({
				table: this.table,
				method: 'delete',
				data: {
					id: node.id
				}
			});

			this.tree.value.deleteNode(node.id);
		}

		//Автоматическую проверку на наличие дочерних элементов
		if (node.folder) {
			try {
				if (!await this.store.model.onBeforeDelete(node)) throw new Error('Удалите дочерние элементы');

				if (node.children.length > 0) {
					throw new Error('Удалите дочерние элементы')
				} else {
					const res = await query({
						table: this.table,
						method: 'treeparents',
						data: {
							id: node.id
						}
					});

					if (res.complete && res.data) throw new Error('Удалите дочерние элементы');
				}

				Swal.fire({
					title: 'Удалить раздел?',
					showCancelButton: true,
					confirmButtonText: 'Да',
					cancelButtonText: 'Отмена'
				}).then(async ({ value }) => value && await deleteNode())
			} catch (error: any) {
				toastError(error.message);
			}
		} else {
			Swal.fire({
				title: 'Удалить элемент?',
				showCancelButton: true,
				confirmButtonText: 'Да',
				cancelButtonText: 'Отмена'
			}).then(async ({ value }) => value && await deleteNode())
		}
	}

	async nodeAdd(node: any, folder = false) {
		const data = await edit({
			table: this.table,
			data: {
				parent: node.folder ? node.id : (node?.parent?.id ? node.parent.id : '00000000-0000-0000-0000-000000000000'),
				node: folder
			},
			form: folder ? 'editgroup' : 'edit'
		});

		if (data) {
			const tree = this.tree.value;

			if (node.folder) {
				node.expanded = true;

				const newNode = tree.createNode(data, node.children, node);
				this.active(newNode);
			} else {
				if (node.parent) {
					const newNode = tree.createNode(data, node.parent.children, node.parent);
					this.active(newNode);
				} else {
					const newNode = tree.createNode(data, tree.tree);
					this.active(newNode);
				}
			}
		}
	}

	createNode(data: any, node: any) {
		const tree = this.tree.value;

		const newNode = tree.createNode(data, node.parent.children, node.parent);
		this.active(newNode);
	}

	async textChanged(newText: any, prevText: any, active: any) {
		await query({
			table: this.table,
			method: "update",
			data: {
				id: active.id,
				name: newText
			}
		});
	}

	async draggingFinish(dragElement: any, item: any, dragmode: any) {
		await query({
			table: this.table,
			method: "treemove",
			from: dragElement.id,
			to: item.id,
			mode: dragmode
		});
	}

	async dblclick(node: any) {
		if (this.props.selectmode) {
			const { folder } = node;
			const { selectFolders, selectElements } = this.props;

			if (folder) {
				if (selectFolders) this.emit('select', node.data);
			} else {
				if (selectElements) this.emit('select', node.data);
			}
		} else {
			await this.nodeEdit(node);
		}
	}

	contextMenu(e: any, node: any) {
		openContextMenu(e, this.getContextMenu(node));
	}

	async nodeOpen(node: any, controller: any) {
		if (this.props.deep != 255 && !node.loaded) {
			node.loaded = true;

			const queryData = deepMerge({
				table: this.table,
				method: 'read',
				params: this.store.fetchParams({
					filters: [
						{
							field: `"${this.table}"."parent"`,
							value: node.id
						},
						...typeof this.settings.filters == 'function' ? this.settings.filters() : this.settings.filters
					],
					orderby: `"${this.table}"."position"`
				})
			});

			const response: any = await query(queryData);

			controller.loadNodeData(node, response.data.rows);
		}
	}

	async fetchData(options: any = {}) {
		const queryData = deepMerge({
			table: this.table,
			method: 'tree',
			params: this.store.fetchParams({
				...options,
				filters: this.settings.filters,
				params: this.settings.fetchParams
			}),
			data: {
				deep: this.props.deep,
				id: this.settings.id
			}
		});

		const response: any = await query(queryData);

		this.data.value = response.data;

		if (this.data.value.length > 0) {
			nextTick(() => {
				if (this.props.selectmode) {
					let node = this.tree.value.findNode(this.settings.id);

					if (!node) node = this.tree.value.findNode(this.data.value[0].id);

					this.active(node);
				} else {
					const node = this.tree.value.findNode(this.data.value[0].id);
					if (node) this.active(node);
				}
			})
		}
	}
}