import { defineAsyncComponent } from 'vue';
export default {
	forms: {
		edit: {
			config: {
				fields: {
					id: {
						config: {
							visible: false
						}
					},
					locality: {},
					name: {},
					name_full: {
						config: {
							visible: false
						}
					},
					category_eva: {}
				}
			},
			component: defineAsyncComponent(() => import('./forms/Edit.vue'))
		},
		list: {
			config: {
				sorting: true,
				fields: {
					id: {
						config: {
							hide: true
						}
					},
					locality: {
					},
					name_full: {}
				}
			},
			component: defineAsyncComponent(() => import('./forms/List.vue'))
		},
		select: {
			panel: {
				width: '80%'
			},
			config: {
				fields: {
					id: {
						config: {
							hide: true
						}
					},
					locality: {},
					name: {}
				}
			}
		}
	}
}