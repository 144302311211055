import api from "@/core/api/api";

// export const reportTableExport = (table: string, params: any = {}) => api.query(`/reports/table/export/${table}`, { data: params, method: "post", responseType: 'blob' });

export const login = (data: any) => api.query(`/auth/login`, { data, method: "put" }, false);
export const logout = () => api.query(`/auth/logout`, { method: "put" }, false);
export const regEmail = (email: string) => api.query(`/auth/registrationemail`, { data: { email }, method: "put" }, false);
export const passwordRestore = (token: string, password: string) => api.query(`/auth/passwordrestore`, { data: { token, password }, method: "put" }, false);
export const passwordSet = (data: any) => api.query(`/auth/passwordset`, { data, method: "put" }, false);
export const registration = (data: any) => api.query(`/auth/registration`, { data, method: "put" }, false);
export const confirmEmail = (token: string) => api.query(`/auth/emailconfirm`, { data: { token }, method: "put" }, false);
export const passwordRecovery = (email: string) => api.query(`/auth/passwordrecovery`, { data: { email }, method: "put" }, false);
export const status = () => api.query(`/auth/status`);
export const proceedToken = () => api.query(`/auth/tokenproceed`);
export const passwordGeneration = () => api.query(`/auth/passwordgeneration`, { method: "get" }, false);
export const passwordChange = (passwordold: string, password: string) => api.query(`/auth/passwordchange`, { data: { passwordold, password }, method: "put" }, false);
