<template>
	<template v-if="access.read || true">
		<SearchInput v-model="controller.searchText.value" v-if="!isSubTable && settings.searchpanel" />

		<div :class="{ 'table-frame': frame || verified && !valid, 'border-danger': verified && !valid, 'transparent-row': settings.transparentTr }">
			<div class="navtable">
				<PanelFun :items="panelFun" v-if="settings.panelFun" />
				<div v-else></div>

				<div class="form-inline" v-if="!isSubTable && pages">
					<Pagination @setpage="selectPage" v-model="controller.page.value" :pages="controller.pages.value" v-if="controller.pages.value > 1" />

					<select class="form-control form-control-sm" v-model="controller.limit.value">
						<option v-for="element of settings.limits" :key="element" :value="element">{{ element }}</option>
					</select>
				</div>
			</div>

			<div class="table-responsive pb-1" :style="containerStyle">
				<table class="table table-striped table-bordered table-hover table-sm unselectable" @keydown="onKeyDown" tabindex="-1">
					<GridHead :controller="controller" :checkedRowSelect="checkedRowSelect" @sort="onSort" />
					<GridBody :controller="controller" :crossot="settings.crossot" @active="activeRow" @select="selectRow" />
					<GridFooter :controller="controller" v-if="settings.footer" />
				</table>
			</div>
		</div>

		<div class="table-invalid-feedback" v-if="feedback && verified">{{ feedback }}</div>
	</template>
</template>

<script>
//Вывод метки и звездочки если есть валидатор
import { onMounted, computed, defineComponent, onUnmounted } from 'vue';

import Pagination from "@/core/components/Pagination";
import SearchInput from '@/core/components/SearchInput';

import GridHead from "./GridHead";
import GridBody from "./GridBody";
import GridFooter from "./GridFooter";
import DBGridController from './controller';

export default defineComponent({
	inheritAttrs: false,

	emits: ['active', 'select', 'store'],

	components: {
		SearchInput, Pagination, GridHead, GridBody, GridFooter
	},

	props: {
		store: {
			type: Object,
			default: null
		},
		config: {
			type: Object,
			default: () => ({})
		},
		readonly: {
			type: Boolean,
			default: false
		},
		form: {
			type: Object,
			default: null
		},
		field: {
			type: String,
			default: null
		},
		validation: {
			type: Boolean,
			default: true
		},
		pages: {
			type: Boolean,
			default: true
		},
		frame: {
			type: Boolean,
			default: false
		}
	},

	setup(props, { emit }) {
		const controller = new DBGridController(props, emit);

		if (!controller.store) {
			console.error('Grid storage not installed');

			return {
				access: { read: false }
			}
		}

		emit('store', controller.store);

		const validation = () => controller.validation();

		const isSubTable = computed(() => !!(controller.store.model.subtable || controller.store.model.offLine));

		onMounted(async () => {
			if (controller.settings.localStorageKey && localStorage[controller.settings.localStorageKey]) {
				const fields = JSON.parse(localStorage[controller.settings.localStorageKey]);

				if (fields.length > 0) controller.setFields(fields);
			}

			if (!controller.store.model.subtable) {
				controller.store.fetchOptions['filters'] = controller.settings.filters;
				controller.store.fetchOptions['params'] = controller.settings.fetchParams;
				controller.store.fetchOptions['data'] = controller.settings.data;

				if (controller.settings.id) controller.store.fetchOptions.id = controller.settings.id;
				if (controller.settings.owner) controller.store.fetchOptions.owner = controller.settings.owner;

				//Установка лимита запускает получение данных
				controller.store.fetchOptions.limit = controller.settings.limit;

				// await controller.store.fetchData();
			}

			if (props.form && props.field) {
				if (controller.stateField.validation && Object.keys(controller.stateField.validation).length)
					props.form.addValidation(validation);
			}

			controller.settings.onCreate(controller);

			if (controller.settings.autoOpen) controller.fetchData();
		});

		onUnmounted(() => {
			if (props.form && props.field) {
				if (controller.stateField.validation && Object.keys(controller.stateField.validation).length)
					props.form.delValidation(validation);
			}
		})

		const containerStyle = {}

		if (controller.settings.height) {
			containerStyle['height'] = controller.settings.height;
			containerStyle['overflow'] = 'scroll';
			containerStyle['overflow-x'] = 'auto';
			containerStyle['overflow-y'] = 'auto';
		}

		return {
			controller,
			openRow: (data) => controller.openRow(data),
			addRow: (data) => controller.addRow(data),
			settings: controller.settings,
			containerStyle,
			onKeyDown: (event) => controller.onKeyDown(event),
			onSort: (sort) => controller.onSort(sort),
			selectRow: (data) => controller.selectRow(data),
			activeRow: (position) => controller.activeRow(position),
			selectPage: (page) => controller.selectPage(page),
			panelFun: controller.panelFun,
			access: computed(() => controller.access),
			feedback: controller.feedback,
			verified: controller.verified,
			valid: controller.valid,
			checkedRowSelect: controller.checkedRowSelect,
			isSubTable
		}
	}
})
</script>

<style>
.table thead th,
.table thead td {
	outline: 0;
	position: relative;
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Chrome/Safari/Opera */
	-khtml-user-select: none;
	/* Konqueror */
	-moz-user-select: none;
	/* Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
}

.table thead .sorting_asc,
.table thead .sorting_desc,
.table thead .sorting {
	cursor: pointer;
}

.table thead .sorting,
.table thead .sorting_asc,
.table thead .sorting_desc,
.table thead .sorting_asc_disabled,
.table thead .sorting_desc_disabled {
	padding-right: 1.5rem;
}

.table thead .sorting:before,
.table thead .sorting:after,
.table thead .sorting_asc:after,
.table thead .sorting_desc:after,
.table thead .sorting_asc_disabled:after,
.table thead .sorting_desc_disabled:after {
	content: "";
	font-family: "emed-icons";
	position: absolute;
	top: 50%;
	right: 0.5rem;
	font-size: 0.75rem;
	margin-top: -0.375rem;
	display: inline-block;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.table thead .sorting:before {
	content: "\e907";
	margin-top: -0.125rem;
	opacity: 0.5;
}

.table thead .sorting:after {
	content: "\e90b";
	margin-top: -0.625rem;
	opacity: 0.5;
}

.table thead .sorting_asc:after {
	content: "\e90b";
	opacity: 1;
}

.table thead .sorting_desc:after {
	content: "\e907";
	opacity: 1;
}

.table thead .sorting_asc_disabled:after {
	content: "";
	opacity: 0.25;
}

.table thead .sorting_desc_disabled:after {
	content: "";
	opacity: 0.25;
}

.table-invalid-feedback {
	width: 100%;
	font-size: 90%;
	color: #f44336;
}

.table-frame {
	padding: 10px;
	border: 1px solid rgba(0, 0, 0, 0.125);
	border-radius: 0.1875rem;
	margin-bottom: 0.625rem;
}
</style>
