<template>
	<ul class="nav nav-pills mb-1">
		<template v-for="(item, i) in items" :key="i">
			<li class="nav-item" v-if="isVisible(item)">
				<a class="nav-link" :class="{ active: index == i}" href="#" @click.prevent="onClick(i)">
					{{ item.caption }}
					<span :class="item.classinfo ? item.classinfo: 'badge bg-info'" v-if="item.info">{{ item.info }}</span>
				</a>
			</li>
		</template>
	</ul>
</template>

<script>
import { nextTick, onMounted, ref } from 'vue';

export default {
	inheritAttrs: false,

	emits: ['change'],

	props: {
		items: {
			type: Array,
			default: () => []
		}
	},

	setup(props, { emit }) {
		const index = ref(0);

		const onClick = (i) => emit('change', index.value = i);

		onMounted(() => {
			nextTick(() => {
				for (const i in props.items) {
					if (props.items[i].active) {
						index.value = Number(i);

						onClick(index.value);

						break;
					}
				}
			})
		})

		const isVisible = (item) => 'visible' in item ? (typeof item.visible == 'function' ? item.visible(item) : item.visible) : true;

		return {
			index,
			onClick,
			isVisible
		}
	}

}
</script>