import { defineAsyncComponent } from 'vue';

export default {
	forms: {
		list: {
			config: {
				sorting: true,
				fields: {
					id: {},
					name: {
						config: {
							visible: true,
							sorting: 'document_types.name'
						}
					},
					name_table: {
						config: {
							visible: true,
							sorting: 'document_types.name_table'
						}
					}
				}
			}
		},
		select: {
			config: {
				fields: {
					id: {
						config: {
							hide: true
						}
					},
					name: {}
				}
			}
		},
		edit: {
			config: {
				fields: {
					id: {},
					name: {},
					name_table: {}
				}
			},
			component: defineAsyncComponent(() => import('./forms/Edit.vue'))
		}
	}
}