import dayjs from "dayjs";
import 'dayjs/locale/ru';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

import 'mosha-vue-toastify/dist/style.css';
import "@/assets/scss/styles.scss";

import { createApp } from 'vue';

import stateStore from '@/core/store/index';

import './ServiceWorker'

import App from './App.vue';
import router from '@/router';

import VFocus from "@/core/directives/VFocus";
import VClickaway from "@/core/directives/VClickaway";

import Card from "@/core/components/Card/index.vue";
import NavBar from '@/core/components/NavBar/index.vue';
import PanelFun from '@/core/components/PanelFun/index.vue';
import UploadFiles from "@/core/components/UploadFiles/index.vue";
import { Tabs, Tab } from "@/core/components/Tabs/index"

import DBEdit from "@/core/components/DB/DBEdit/index.vue";
import DBForm from "@/core/components/DB/DBForm/index.vue";
import DBGrid from "@/core/components/DB/DBGrid/index.vue";
import DBTree from "@/core/components/DB/DBTree/index.vue";
import DBPanel from "@/core/components/DB/DBPanel/index.vue";
import DBTable from "@/core/components/DB/DBTable/index.vue";
import DBLabel from "@/core/components/DB/DBLabel/index.vue";
import DBEditor from "@/core/components/DB/DBEditor/index.vue";
import DBCaption from "@/core/components/DB/DBCaption/index.vue";
import DBMultiEdit from '@/core/components/DB/DBMultiEdit/index.vue';
import DBQuestionary from '@/core/components/DB/DBQuestionary/index.vue';

import '@/core/wsclient';

dayjs.locale('ru');
dayjs.extend(quarterOfYear);

createApp(App)
	.use(stateStore)
	.use(router)

	.directive('focus', VFocus)
	.directive('clickaway', VClickaway)

	.component("Tab", Tab)
	.component("Tabs", Tabs)
	.component("Card", Card)
	.component("NavBar", NavBar)
	.component("PanelFun", PanelFun)
	.component("UploadFiles", UploadFiles)

	.component("DBEdit", DBEdit)
	.component("DBForm", DBForm)
	.component("DBGrid", DBGrid)
	.component("DBTree", DBTree)
	.component("DBPanel", DBPanel)
	.component("DBTable", DBTable)
	.component("DBLabel", DBLabel)
	.component("DBEditor", DBEditor)
	.component("DBCaption", DBCaption)
	.component("DBMultiEdit", DBMultiEdit)
	.component("DBQuestionary", DBQuestionary)

	.mount('#app')
