<template>
	<div class="dropdown-menu show" :style="style" v-if="controller.menuShow.value" ref="el">
		<DropdownMenu :items="controller.menuItems.value" :left="left"></DropdownMenu>
	</div>
</template>

<script>
import { onMounted, onUnmounted, ref } from 'vue';
import DropdownMenu from '@/core/components/DropdownMenu/index.vue';

export default {
	components: {
		DropdownMenu
	},

	props: {
		style: {
			type: String,
			default: 'right: 0px; left: unset; max-width: 500px; max-height: 500px; overflow: auto;'
		},
		controller: {
			type: Object,
			default: () => ({})
		},
		left: {
			type: Boolean,
			default: false
		}
	},

	setup(props) {
		const el = ref(null);

		const closePopup = () => {
			props.controller.menuShow.value = false;
		}

		onMounted(() => {
			document.addEventListener("click", closePopup);
		})

		onUnmounted(() => {
			document.removeEventListener("click", closePopup);
		})

		return {
			el
		}
	}
}
</script>

<style></style>